import React from 'react';
import {Link,
    useParams} from "react-router-dom";

import JSONMusicComposed from './data/music-composed.json';
import JSONTalks from './data/talks.json';
import JSONArticles from './data/articles.json';
import JSONCode from './data/code.json';
import JSONAbout from './data/about.json';
import JSONTopics from './data/topics.json';

import {IconTopic} from './components/IconTopic';
import {IconTopicProps} from './components/IconTopic';
import {IconTopicSVGProps} from './components/IconTopic';
import {IconExpand} from './components/IconExpand';
import {IconQuoteSVG} from './components/IconQuote';

import {Header} from './components/Header';
import {Footer} from './components/Footer';

import {cnContainer,
    cnCol2FlexCol,
    iconMap,
    colors,
    assetsRoot,
    } from './components/Common';


interface DataLinkResource {
    title: string;
    url: string;
}

interface DataAudioResource {
    duration: string;
    fileSize: string;
    format: string;
    title: string;
    url: string;
}
interface DataMusic {
    description: string;
    filesAudio: Array<DataAudioResource>;
    filesScore: Array<DataLinkResource>;
    infoDate: string;
    infoDuration: string;
    infoInst: string;
    titleMain: string;
    titleSub: string;
}

interface DataTalks {
    infoDate: string;
    title: string;
    publisher: string;
    links: Array<DataLinkResource>;
}

interface DataArticles {
    infoDate: string;
    title: string;
    publisher: string;
    links: Array<DataLinkResource>;
}

interface DataCode {
    title: string;
    description: string;
    involvement: string;
    technologies: string;
    links: Array<DataLinkResource>;
}

interface DataAbout {
    title: string;
    description: string;
    links: Array<DataLinkResource>;
}

interface DataTopic {
    title: string;
    intro: string;
    featured: Array<FeaturedItem>;
}

const dataMusicComposed = new Map<string, DataMusic>(Object.entries(JSONMusicComposed));

const dataTalks = new Map<string, DataTalks>(Object.entries(JSONTalks));

const dataArticles = new Map<string, DataArticles>(Object.entries(JSONArticles));

const dataCode = new Map<string, DataCode>(Object.entries(JSONCode));

const dataAbout = new Map<string, DataAbout>(Object.entries(JSONAbout));

const dataTopics = new Map<string, DataTopic>(Object.entries(JSONTopics));

const EMPTY_ARRAY_STR: Array<string> = [];

// const cnCol3FlexColShrinkable = "w-1/3 flex flex-col py-2 px-2 sm:w-1/3 lg:w-1/3"
// const cnColFieldBody = "px-4 py-2 rounded-md bg-slate-800";


// External links:
// Google Scholar: https://scholar.google.com/citations?user=1DkgeScAAAAJ
// GitHub:

function DownloadMusic(dar: DataAudioResource) {
    const link = dar.title ? `Download Audio "${dar.title}" (${dar.format})` : `Download Audio (${dar.format})`;
    let url;
    if (dar.url.startsWith('http')) {
        url = dar.url;
    }
    else {
        url = `${assetsRoot}${dar.url}`;
    }
    return (
    <li className="flex" key={dar.url}>
        <a href={url}
        className="bg-slate-800 p-2 w-full rounded-lg"
        target="_blank"
        rel="noopener noreferrer"
        key={`audio-${dar.url}`}
        >
        {link}
        </a>
    </li>)
}

function DownloadScore(dlr: DataLinkResource) {
    const url = `${assetsRoot}${dlr.url}`
    return (
    <li className="flex" key={url}>
        <a href={url}
        className="bg-slate-800 p-2 w-full rounded-lg"
        target="_blank"
        rel="noopener noreferrer"
        key={`score-${dlr.url}`}
        >
        {`Download ${dlr.title} (pdf)`}
        </a>
    </li>)
}

function DownloadLink(dlr: DataLinkResource) {
    let url = "";
    if (dlr.url.startsWith("/articles")) {
        url = `${assetsRoot}${dlr.url}`;
    }
    else {
        url = dlr.url;
    }
    return (
    <li className="flex" key={url}>
        <a href={url}
        className="bg-slate-800 p-2 w-full rounded-md"
        target="_blank"
        rel="noopener noreferrer"
        key={dlr.url}
        >
        {dlr.title}
        </a>
    </li>)
}


function LinkListing(dlrs: Array<DataLinkResource>) {
    if (!dlrs) return;
    return (
    <ul className="mt-2 space-y-2 text-base">
        {dlrs.map(DownloadLink)}
    </ul>);
}

function Paragraphs(content: string) {
    const parts = content.split('\n');
    const part_count = parts.length;
    function Paragraph(item: string, idx: number) {
        if (idx < part_count-1) {
            return <span key={idx}>{item}<br /><br /></span>
        }
        else {
            return <span key={idx}>{item}</span>
        }
    }
    return (
        <React.Fragment>
        {parts.map(Paragraph)}
        </React.Fragment>


    // return (
    // <React.Fragment>
    // {content.split('\n').map((item, idx) => {
    //     return <span key={idx}>{item}<br /><br /></span>
    // })}
    // </React.Fragment>
    )
}


//--------------------------------------------------------------------------
interface ListingProps {
    keys: Array<string>;
}

function ListingMusic({keys}: ListingProps) {

    const [mcDetail, setMCDetail] = React.useState(new Map<string, boolean>());

    let musicComposedDisplay = keys;
    if (keys.length === 0) { // if empty, get all
        musicComposedDisplay = Array.from(dataMusicComposed.keys());
    }

    function SubTitle(key: string) {
        const m = dataMusicComposed.get(key);
        if (!m) return;
        if (m.titleSub) {
            return <div className="text-slate-500 text-sm italic">
            {m.titleSub}
            </div>;
        }
    }

    function AudioList(key: string) {
        const m = dataMusicComposed.get(key);
        if (!m) return;
        if (m.filesAudio.length) {
            return <ul className="mt-2 space-y-2 text-base">
            {m.filesAudio.map(DownloadMusic)}
            </ul>;
        }
    }

    function ScoreList(key: string) {
        const m = dataMusicComposed.get(key);
        if (!m) return;
        if (m.filesScore.length) {
            return <ul className="mt-2 space-y-2 text-base">
            {m.filesScore.map(DownloadScore)}
            </ul>;
        }
    }

    function Description(key: string) {
        const m = dataMusicComposed.get(key);
        if (!m) return;

        function onclickMCDetailToggle() {
            if (mcDetail.has(key)) {
                mcDetail.set(key, !mcDetail.get(key));
            }
            else {
                mcDetail.set(key, true);
            }
            setMCDetail(new Map<string, boolean>(mcDetail));
        }

        if (m.description) {
            if (mcDetail.get(key)) {
                return (
                <div className="mt-2 p-2 bg-slate-800 text-base">
                    {Paragraphs(m.description)}
                    <div>
                    <button
                        className="mt-2 p-2 bg-slate-700 rounded-md text-sm"
                        onClick={onclickMCDetailToggle}
                        >Hide</button>
                    </div>
                </div>
                );
            }
            else {
                return <button
                    className="mt-2 p-2 bg-slate-600 rounded-md text-sm"
                    onClick={onclickMCDetailToggle}
                    >
                Details
                </button>;
            }
        }
    }

    function ItemMusic(key: string) {
        const m = dataMusicComposed.get(key);
        if (!m) return;

        const title = `${m.titleMain} (${m.infoDate})`;
        return (
        <li key={`music-${key}`}>
            <div className="bg-slate-700 px-2 py-2 rounded-sm">
                <div className="text-slate-400 text-bold text-lg">
                    {title}
                </div>
                {SubTitle(key)}
                {Description(key)}
                {AudioList(key)}
                {ScoreList(key)}
            </div>
        </li>)
    }

    return (
        <div className="px-2 py-2 mt-2 rounded-md bg-slate-800">
        <ul className="space-y-2 text-base font-sans text-slate-400 text-base">{
        musicComposedDisplay.map(ItemMusic)
        }</ul>
        </div>
        )
}


//--------------------------------------------------------------------------
function ListingAbout({keys}: ListingProps) {

    const [aboutDetail, setAboutDetail] = React.useState(new Map<string, boolean>());

    const aboutDisplay = Array.from(dataAbout.keys());

    function Description(key: string) {
        function onclickAboutToggle() {
            if (aboutDetail.has(key)) {
                aboutDetail.set(key, !aboutDetail.get(key));
            }
            else {
                aboutDetail.set(key, true);
            }
            setAboutDetail(new Map<string, boolean>(aboutDetail));
        }

        const m = dataAbout.get(key);
        if (!m) return;

        if (aboutDetail.get(key)) {
            return (
            <div className="mt-2 p-2 bg-slate-800 text-base rounded-sm">
                {Paragraphs(m.description)}
                <div>
                <button
                    className="mt-2 p-2 bg-slate-600 rounded-md text-xs"
                    onClick={onclickAboutToggle}
                    >Hide</button>
                </div>
            </div>
            );
        }
        else {
            return <button
                className="mt-2 p-2 bg-slate-600 rounded-md text-xs"
                onClick={onclickAboutToggle}
                >
            Details
            </button>;
        }
    }

    function ItemAbout(key: string) {
        const m = dataAbout.get(key);
        if (!m) return;

        return (
        <li key={key}>
            <div className="bg-slate-700 px-2 py-2 rounded-sm">
                <div className="text-slate-400 text-bold text-lg">
                    {m.title}
                </div>
                {Description(key)}
            </div>
        </li>)
    }

    return (
        <div className="px-2 py-2 mt-2 rounded-md bg-slate-800">
        <ul className="space-y-2 text-base font-sans text-slate-400 text-base">{
        aboutDisplay.map(ItemAbout)
        }</ul>
        </div>
        )
}


//--------------------------------------------------------------------------
function ListingTalks({keys}: ListingProps) {

    let talksDisplay = keys;
    if (keys.length === 0) { // if empty, get all
        talksDisplay = Array.from(dataTalks.keys());
    }

    function ItemTalk(key: string) {
        const m = dataTalks.get(key);
        if (!m) return;

        return (
        <li key={key}>
            <div className="bg-slate-700 px-2 py-2 rounded-sm">
                <div className="text-slate-400 text-bold text-lg">
                    {m.title}
                </div>
                <div className="text-slate-500 text-base">{m.publisher} {m.infoDate}</div>
                {LinkListing(m.links)}
            </div>
        </li>)
    }

    return (
        <div className="px-2 py-2 mt-2 rounded-md bg-slate-800">
        <ul className="space-y-2 text-base font-sans text-slate-400 text-base">{
        talksDisplay.map(ItemTalk)
        }</ul>
        </div>
        )
}

//--------------------------------------------------------------------------
function ListingArticles({keys}: ListingProps) {

    let articlesDisplay = keys;
    if (keys.length === 0) { // if empty, get all
        articlesDisplay = Array.from(dataArticles.keys());
    }

    function ItemArticle(key: string) {
        const m = dataArticles.get(key);
        if (!m) return;

        return (
        <li key={key}>
            <div className="bg-slate-700 px-2 py-2 rounded-sm">
                <div className="text-slate-400 text-bold text-lg mb-2">
                    {m.title}
                </div>
                <div className="text-slate-500 text-sm">{m.publisher} {m.infoDate}</div>
                {LinkListing(m.links)}
            </div>
        </li>)
    }

    return (
        <div className="px-2 py-2 mt-2 rounded-md bg-slate-800">
        <ul className="space-y-2 text-base font-sans text-slate-400 text-base">{
        articlesDisplay.map(ItemArticle)
        }</ul>
        </div>
        )
}

//--------------------------------------------------------------------------
function ListingCode({keys}: ListingProps) {

    let codeDisplay = keys;
    if (keys.length === 0) { // if empty, get all
        codeDisplay = Array.from(dataCode.keys());
    }

    function ItemCode(key: string) {
        const m = dataCode.get(key);
        if (!m) return;

        return (
        <li key={key}>
            <div className="bg-slate-700 px-2 py-2 rounded-sm">
                <div className="text-slate-400 text-bold text-xl">
                    {m.title}
                </div>
                <div className="text-slate-400 text-base">
                    {m.description}
                </div>
                <div className="text-slate-500 text-sm">{m.involvement}</div>
                <div className="text-slate-500 text-sm">{m.technologies}</div>
                {LinkListing(m.links)}
            </div>
        </li>)
    }

    return (
        <div className="px-2 py-2 mt-2 rounded-md bg-slate-800">
        <ul className="space-y-2 text-base font-sans text-slate-400 text-base">{
        codeDisplay.map(ItemCode)
        }</ul>
        </div>
        )
}

//--------------------------------------------------------------------------

interface FeaturedItem {
    key: string,
    description: string,
}

interface SectionFeaturedProps {
    Listing: React.FC<ListingProps>;
    featured: Array<FeaturedItem>;
    title: string;
    colorQuote: string;
}

function SectionFeatured({
        Listing,
        featured,
        title,
        colorQuote,
        }: SectionFeaturedProps) {

    const keys: Array<string> = [];
    featured.forEach(e => keys.push(e.key));

    if (keys.length === 0){
        return <React.Fragment />
    }

    function itemFeature(feature: FeaturedItem) {
        // if no description, just return the feature
        if (!feature.description) {
            return (
            <div key={feature.key}>
                <Listing keys={[feature.key]} />
            </div>
            )
        }

        return (
        <div key={feature.key}>
            <div className="px-4 pt-4 inline-flex">
                <div className="">
                    {IconQuoteSVG(colorQuote)}
                </div>
                <p className="pl-2 italic text-slate-400 text-left text-base">
                    {feature.description}
                </p>
            </div>
            <Listing keys={[feature.key]} />
        </div>
        )
    }

    return (
    <React.Fragment>
    <div className="py-2 mt-2">
        <h3 className="px-4 text-slate-500 text-left text-2xl">
            Featured {title}
        </h3>
        {featured.map(itemFeature)}
    </div>
    </React.Fragment>
    )

}

//--------------------------------------------------------------------------

interface TopicControlsProps {
    title: string;
    expand: boolean;
    func_expand: () => void;
    position_top: boolean;
}

function TopicControls({title, expand, func_expand, position_top}: TopicControlsProps) {

    const label = IconExpand({
        expand: expand,
        colorCollapse: colors.slate400,
        colorExpand: colors.slate400,
        });

    const cnAbsolute = position_top ? "absolute left-0 right-0" : "absolute left-0 right-0  bottom-full"

    const cnTop = "w-full py-2 h-4 flex-1 rounded-sm px-2 bg-gradient-to-t from-slate-900/0 to-slate-900"

    // do not want this height to be 12, but otherwise it fills from the top and pushes down the icon
    const cnBottom = "w-full py-2 h-12 flex-1 rounded-sm px-2 bg-gradient-to-b from-slate-900/0 to-slate-900"

    // const cnTop = "w-full py-2 h-8 flex-1 rounded-sm px-2 bg-slate-900/80"
    // const cnBottom = "w-full py-2 h-12 flex-1 rounded-sm px-2 bg-slate-900/80"


    // outer div must be relative for absolute positioning to work
    return (
    <div className="relative">
    <div className={cnAbsolute}>
        <div className={position_top ? cnTop : cnBottom}>
            <button
                className="p-2 rounded-full   bg-slate-700/60 hover:bg-slate-600/60 border border-slate-600 border-x-1 float-right"
                onClick={func_expand}
                >{label}
            </button>
        </div>
    </div>
    </div>
    )}

interface TopicProps {
    slug: string;
    dataTopic: DataTopic;
    contentLeft: boolean;
    Listing: React.FC<ListingProps>;
    iconSVGProps: IconTopicSVGProps;
    colorQuote: string;
    topicExpand: Map<string, boolean>;
    setTopicExpand: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
}

// A Topic collects items in a domain, such as music, articles, about, etc.
function Topic({
        slug,
        dataTopic,
        contentLeft,
        Listing,
        iconSVGProps,
        colorQuote,
        topicExpand,
        setTopicExpand
        }: TopicProps
        ) {
    const title = dataTopic.title;
    const featured = dataTopic.featured;

    function onclickTopicExpandToggle() {
        if (topicExpand.has(slug)) {
            topicExpand.set(slug, !topicExpand.get(slug));
        }
        else {
            topicExpand.set(slug, true);
        }
        setTopicExpand(new Map<string, boolean>(topicExpand));
    }


    let expand = false;
    if (topicExpand.has(slug)) {
        if (topicExpand.get(slug)) {
            expand = true;
        }
    }

    function allHeading(featureCount: number) {
        // only show an all heading if we have featured; otherwise, no need to have this heading
        if (featureCount > 0) {
            return (<h3 className="px-4 pt-6 pb-2 text-slate-500 text-left text-2xl">
            All {title}
            </h3>)
        }
    }

    // border border-slate-700/80
    const cnScroll = expand ? "" : "h-96 overflow-x-auto"

    // handle toggling body and image
    function content(body: boolean) {
        if (body) {
            return (
            <div className="">
                <TopicControls
                    title={title}
                    expand={expand}
                    func_expand={onclickTopicExpandToggle}
                    position_top={true}
                />
                <div className={cnScroll}>
                    <div className="h-12" />
                    <SectionFeatured
                        Listing={Listing}
                        featured={featured}
                        title={title}
                        colorQuote={colorQuote}
                     />
                    {allHeading(featured.length)}
                    <Listing keys={EMPTY_ARRAY_STR}/>
                    <div className="h-14 mt-2" />
                </div>
                <TopicControls
                    title={title}
                    expand={expand}
                    func_expand={onclickTopicExpandToggle}
                    position_top={false}
                />
            </div>
            )
        }
        // return non-body (icon)
        const ip: IconTopicProps = {
                fadeLeft: contentLeft,
                positionLeft: !contentLeft,
                iconSVGProps: iconSVGProps,
            }

        const cnTopicIntro = "my-4 text-base text-zinc-500 font-sans " + (contentLeft ? "text-right" : "text-left");

        return (
        <React.Fragment>
        <Link to={`/${slug}`}>
            {IconTopic(ip)}
        </Link>
        <div className="mt-2">
            <h3 className="text-slate-400 text-bold text-2xl font-sans">{title}
            </h3>
        </div>
        <div className={cnTopicIntro}>
            {dataTopic["intro"]}
        </div>
        </React.Fragment>
        )
    }

    return (
    <div className={cnContainer} key={slug}>
        <div className={cnCol2FlexCol}>
            {content(contentLeft)}
        </div>
        <div className={cnCol2FlexCol}>
            {content(!contentLeft)}
        </div>
    </div>
)}

//--------------------------------------------------------------------------


function App() {

    // this must be outside of topics and passed down into them
    const [topicExpand, setTopicExpand] = React.useState(new Map<string, boolean>());

    // Define all unique topic params per topic, then use to populate full TopicProps, below
    const topicParams: Array<[string, React.FC<ListingProps>, string]> = [
        ["code", ListingCode, colors.fuchsia600],
        ["articles", ListingArticles, colors.lime600],
        ["talks", ListingTalks, colors.lime800],
        ["music-composed", ListingMusic, colors.violet700],
        ["about", ListingAbout, colors.lime500],
    ];

    // this mapping is invariant; how to cache?
    const topicMap = new Map<string, TopicProps>();
    topicParams.forEach(([slug, listing, colorQuote]) => {
        topicMap.set(slug, {
            slug: slug,
            contentLeft: false,
            dataTopic: dataTopics.get(slug)!,
            Listing: listing,
            iconSVGProps: iconMap.get(slug)!,
            colorQuote: colorQuote,
            topicExpand: topicExpand,
            setTopicExpand: setTopicExpand
        })
    })

    const params = useParams();
    let topicsDisplay: Array<TopicProps> = [];

    if (params && params.topic) {
        topicsDisplay.push(topicMap.get(params.topic)!);
        // NOTE: directly setting this but not calling setTopicExpand works but is probably not correct
        // topicExpand.set(params.topic, true);
        // setTopicExpand(new Map<string, boolean>(topicExpand));
    }
    else {
        topicMap.forEach((t) => topicsDisplay.push(t));
        // topicMap.forEach((t) => topicExpand.set(t.slug, false));
        // setTopicExpand(new Map<string, boolean>(topicExpand));
    }

    return (
    <div>
        <div className="max-w-5xl mx-auto px-4">
        <Header />
        {topicsDisplay.map(Topic)}
        <Footer />
        </div>
    </div>
    );
}

export default App;
