
import {versionSite,
    } from './Common';

export function Footer() {
    return (
    <div className="flex flex-wrap h-20">
        <div className="mx-4">
          <p className="text-left text-xs text-zinc-700 leading-4 font-sans">flexatone.net site v{versionSite}. Copyright 1996-2023. Post comments or questions at the <a
            className="text-slate-600 "
            href={"https://github.com/flexatone/flexatone-comments/issues"}
            target="_blank"
            rel="noopener noreferrer"
            >flexatone-comments</a> GitHub repository.
          </p>
        </div>
    </div>
)}