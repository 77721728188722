

function IconExpandSVG(fill: string) {
    return (
    <svg width="16" height="16" fill={fill} viewBox="0 0 16 16">

    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>

    </svg>);
}

function IconCollapseSVG(fill: string) {
    return (
    <svg width="16" height="16" fill={fill} viewBox="0 0 16 16">

    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>

    </svg>);
}


export interface IconExpandProps {
    expand: boolean;
    colorExpand: string;
    colorCollapse: string;
}

export function IconExpand(
    {expand, colorExpand, colorCollapse}: IconExpandProps
){
    const icon = expand ? IconCollapseSVG(colorCollapse) : IconExpandSVG(colorExpand);

    return icon;
}
