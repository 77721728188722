
import {IconTopicSVGProps} from './IconTopic';


export const cnContainer = "flex flex-wrap px-2 py-2 my-4 bg-slate-900 rounded-md";
// const cnCol1FlexCol = 'flex flex-col py-2 px-2 sm:w-1/1 lg:w-1/1'

export const cnCol2FlexCol = 'flex flex-col py-2 px-2 w-full sm:w-1/2 lg:w-1/2';

export const versionSite = "5.1.3";
export const assetsRoot = "https://flexatone.s3.us-west-1.amazonaws.com";

export const colors = {
    slate950: "#080d17",
    slate900: "#0f172a",
    slate800: "#1e293b",
    slate700: "#334155",
    slate600: "#475569",
    slate500: "#64748b",
    slate400: "#94a3b8",

    lime400: "#a3e635",
    lime500: "#84cc16",
    lime600: "#65a30d",
    lime700: "#4d7c0f",
    lime800: "#3f6212",
    lime900: "#365314",

    fuchsia400: "#e879f9",
    fuchsia500: "#d946ef",
    fuchsia600: "#c026d3",
    fuchsia700: "#a21caf",
    fuchsia800: "#86198f",
    fuchsia900: "#701a75",

    violet400: "#a78bfa",
    violet500: "#8b5cf6",
    violet600: "#7c3aed",
    violet700: "#6d28d9",
    violet800: "#5b21b6",
    violet900: "#4c1d95",
};


const iconLibrary: Array<IconTopicSVGProps> = [
    {
        label: 'banner',
        pattern: "p-dot",
        scale: 0.75,
        rotate: 45,
        maskPosition: "l",
        maskTimesStart: [20, 80, 240, 460],
        colorMask: colors.slate950,
        colorPatternBG: colors.lime900,
        colorPatternFG1: colors.lime400,
        colorPatternFG2: colors.lime700,
        colorPatternFG3: colors.lime600,
        colorPatternFG4: colors.lime800,
    },
    {
        label: 'code',
        pattern: "p-wave",
        scale: 1,
        rotate: 0,
        maskPosition: "r",
        maskTimesStart: [5, 40, 120],
        colorMask: colors.slate950,
        colorPatternBG: colors.fuchsia900,
        colorPatternFG1: colors.fuchsia500,
        colorPatternFG2: colors.fuchsia700,
        colorPatternFG3: colors.violet900,
        colorPatternFG4: colors.fuchsia800,
    },
    {
        label: 'articles',
        pattern: "p-circ",
        scale: 1.25,
        rotate: 15,
        maskPosition: "t",
        maskTimesStart: [15, 60, 140],
        colorMask: colors.slate950,
        colorPatternBG: colors.lime900,
        colorPatternFG1: colors.lime700,
        colorPatternFG2: colors.lime800,
        colorPatternFG3: colors.lime600,
        colorPatternFG4: colors.lime800,
    },
    {
        label: 'talks',
        pattern: "p-wave",
        scale: 2,
        rotate: 0,
        maskPosition: "r",
        maskTimesStart: [25, 100, 220],
        colorMask: colors.slate950,
        colorPatternBG: colors.lime900,
        colorPatternFG1: colors.fuchsia900,
        colorPatternFG2: colors.lime700,
        colorPatternFG3: colors.lime600,
        colorPatternFG4: colors.lime800,
    },
    {
        label: 'music-composed',
        pattern: "p-dot",
        scale: 1.25,
        rotate: 45,
        maskPosition: "b",
        maskTimesStart: [30, 90, 160],
        colorMask: colors.slate950,
        colorPatternBG: colors.fuchsia900,
        colorPatternFG1: colors.fuchsia600,
        colorPatternFG2: colors.fuchsia700,
        colorPatternFG3: colors.violet600,
        colorPatternFG4: colors.fuchsia800,
    },
    {
        label: 'about',
        pattern: "p-wave",
        scale: 1,
        rotate: 0,
        maskPosition: "t",
        maskTimesStart: [20, 70, 200],
        colorMask: colors.slate950,
        colorPatternBG: colors.fuchsia900,
        colorPatternFG1: colors.lime400,
        colorPatternFG2: colors.lime700,
        colorPatternFG3: colors.lime600,
        colorPatternFG4: colors.lime800,
    },
]

// NOTE: label needs to be part of IconTopicSVGProps to be used to configure a unique pattern idea within the SVG
export const iconMap = new Map<string, IconTopicSVGProps>(
    iconLibrary.map(e => [e.label, e]));

