import { useRouteError,
    isRouteErrorResponse,
 } from "react-router-dom";

import {Header} from './Header';
import {Footer} from './Footer';

import {cnContainer,
    } from './Common';



export default function RouteError() {
    const error = useRouteError();
    console.error(error);
    let msg = "Error";
    if (isRouteErrorResponse(error)) {
        msg = `${error.status} ${error.data}`;
    }

    return (
    <div>
        <div className="max-w-5xl mx-auto px-4">

        <Header />

        <div className={cnContainer}>
            <span className="text-2xl text-slate-400 text-bold">{msg}</span>
        </div>


        <Footer />
        </div>
    </div>
)}