import {Link} from "react-router-dom"

import {Banner} from './Banner';
import {cnContainer,
    cnCol2FlexCol,
    iconMap,
    } from './Common';
import {IconTopic} from './IconTopic';


export function Header() {
    return (
    <div className={cnContainer}>
        <div className={cnCol2FlexCol}>
            <Link to="..">
                {IconTopic({
                  fadeLeft: false,
                  positionLeft: true,
                  iconSVGProps: iconMap.get("banner")!,
                })}
            </Link>
        </div>
        <div className={cnCol2FlexCol}>
            <Link to="..">
                <Banner />
            </Link>
        </div>
    </div>
    )
}